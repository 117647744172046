function makeDateReadable(str) {
	if (str === '') return;
	const year = str.slice(0, 4);
	const month = str.slice(5, 7);
	const day = str.slice(8, 10);

	let output = '';
	// Number to Uppercase whole word
	if (month === '01') output = 'January';
	if (month === '02') output = 'February';
	if (month === '03') output = 'March';
	if (month === '04') output = 'April';
	if (month === '05') output = 'May';
	if (month === '06') output = 'June';
	if (month === '07') output = 'July';
	if (month === '08') output = 'August';
	if (month === '09') output = 'September';
	if (month === '10') output = 'October';
	if (month === '11') output = 'November';
	if (month === '12') output = 'December';

	// Day number to number with suffix
	if (day === '01' || day === '21' || day === '31') output = `${output} ${day}st`;
	if (day === '02' || day === '22') output = `${output} ${day}nd`;
	if (day === '03' || day === '23') output = `${output} ${day}rd`;
	if (day === '04' || day === '05' || day === '06' || day === '07' || day === '08' || day === '09' || day === '10' || day === '11' || day === '12' || day === '13' || day === '14' || day === '15' || day === '16' || day === '17' || day === '18' || day === '19' || day === '20' || day === '24' || day === '25' || day === '26' || day === '27' || day === '28' || day === '29' || day === '30') output = `${output} ${day}th`;


	return `${output}, ${year}`;
}

module.exports = (timestamp) => {
	return makeDateReadable(timestamp);
}
